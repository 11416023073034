<template>
    <div class="read-forward">
        <h2 class="title">阅读与转发（{{listData.length}}）</h2>
        <div class="read-forward-body">
            <ul class="read-forward-list" v-loading="readForwardLoading">
                <li v-if="listData.length > 0" v-for="(item,index) in listData" :key="index">
                    <avatar
                        :userId="item.viewerInfo.id"
                        :enableLink="false"
                        :src="item.viewerInfo.avatarUrl"
                        size="sm"
                    ></avatar>
                    <span>
                        <span class="name">{{ item.viewerInfo.realName }}</span>
                        <span class="short-name" v-show="item.viewerInfo.nickname">@{{ item.viewerInfo.nickname }}</span>
                    </span>
                    <span class="status" :class="{'done': item.hasRead}">
                        {{ item.hasRead ? '已读' : '未读'}}
                    </span>
                </li>

                <li
                    class="no-more"
                    v-if="!isNoMore"
                    @click="handleGetMore"
                >加载更多》</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Avatar from '#/component/common/avatar.vue';
    export default {
        name: "cloudRecommendation-readAndForward",
        components: {
            Avatar
        },
        props: {
            listData: {
                default: [],
                type: Array
            },
            isNoMore: false,
            readForwardLoading: false,
        },
        mounted (){
            console.log(this.listData);
        },
        methods: {
            handleGetMore(){
                this.$emit('getMore');
            }
        }
    }
</script>

<style lang="scss" scope>
    .read-forward{
        text-align: left;
        padding: 22px 0;

        .title{
            font-size: 18px;
            color: #333333;
            padding: 10px 0;
            text-indent: 18px;
            position: relative;

            &:before{
                display: block;
                content: "";
                background-color: #00BD9C;
                width: 8px;
                height: 1em;
                position: absolute;
            }
        }

        .read-forward-body{
            // margin: 15px;
            // padding: 20px 20px 0 20px;
            padding: 0 20px;
            background-color: #fff;
            // border:1px solid rgba(151, 151, 151, 0.3);

            .read-forward-list{
                margin-bottom: 0;
                li{
                    margin-bottom: 20px;
                    line-height: 30px;
                }

                .avatar-container{
                    display: inline-block;
                    float: left;
                    line-height: normal;
                }

                .name{
                    margin-left: 10px;
                    color: #444;
                }

                .short-name{
                    margin-left: 6px;
                    font-size: 12px;
                    color: #999999;
                }

                .status{
                    float: right;
                    color: $primary;
                    &.done{
                        color: #999999;
                    }
                }

                .no-more{
                    margin: 0;
                    // padding: 10px 0;
                    text-align: center;
                    // border-top: 1px solid #ddd;
                    color: $primary;
                    font-size: 12px;
                    lind-height: 1;
                    cursor: pointer;
                }
            }
        }
    }
</style>
