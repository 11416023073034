var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "read-forward" }, [
    _c("h2", { staticClass: "title" }, [
      _vm._v("阅读与转发（" + _vm._s(_vm.listData.length) + "）"),
    ]),
    _c("div", { staticClass: "read-forward-body" }, [
      _c(
        "ul",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.readForwardLoading,
              expression: "readForwardLoading",
            },
          ],
          staticClass: "read-forward-list",
        },
        [
          _vm._l(_vm.listData, function (item, index) {
            return _vm.listData.length > 0
              ? _c(
                  "li",
                  { key: index },
                  [
                    _c("avatar", {
                      attrs: {
                        userId: item.viewerInfo.id,
                        enableLink: false,
                        src: item.viewerInfo.avatarUrl,
                        size: "sm",
                      },
                    }),
                    _c("span", [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.viewerInfo.realName)),
                      ]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.viewerInfo.nickname,
                              expression: "item.viewerInfo.nickname",
                            },
                          ],
                          staticClass: "short-name",
                        },
                        [_vm._v("@" + _vm._s(item.viewerInfo.nickname))]
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "status", class: { done: item.hasRead } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.hasRead ? "已读" : "未读") +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e()
          }),
          !_vm.isNoMore
            ? _c(
                "li",
                { staticClass: "no-more", on: { click: _vm.handleGetMore } },
                [_vm._v("加载更多》")]
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }