var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cloud-recommendation" },
    [
      _c("candidateBaseInfoStore", {
        ref: "candidateBaseInfoStore",
        attrs: {
          candidateDetailData: _vm.candidateDetailData,
          "candidate-id": _vm.candidateDetailData.id,
          "is-firm": _vm.candidateDetailData.isFirm,
          "resume-id": _vm.candidateDetailData.tbdResumeId,
          recommendationid: _vm.recommendationid,
          "is-cloud-recommendation": true,
          shareData: _vm.shareData,
        },
      }),
      _c("div", { staticClass: "recommendation-content" }, [
        _c(
          "div",
          { staticClass: "pull-left" },
          [
            _c("div", { staticClass: "type-tab" }, [
              _c("div", { staticClass: "tab visible" }, [
                _c(
                  "ul",
                  { staticClass: "selection-list" },
                  _vm._l(_vm.nodeList, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.value,
                        staticClass: "selection-item visible",
                        class: { active: item.value == _vm.activeValue },
                        on: {
                          click: function ($event) {
                            return _vm.routernavigate(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "tab-wrapper" }, [
                          _c("span", [_vm._v(_vm._s(item.text))]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _c("candidate-resume", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeValue == 1 || _vm.activeValue == 4,
                  expression: "activeValue == 1 || activeValue == 4",
                },
              ],
              ref: "candidateResume",
              staticClass: "cloud-recommendation-tab-view",
              attrs: {
                activeValue: _vm.activeValue,
                id: "candidate-resume",
                shareData: _vm.shareData,
                candidateDetailData: _vm.candidateDetailData,
                "candidate-id": _vm.candidateDetailData.candidateId,
                "is-firm": true,
                "can-edit": false,
                "is-cloud-recommendation": true,
                "resume-id": _vm.candidateDetailData.resumeId,
              },
              on: { "candidate-detail-refresh": _vm.refreshdetail },
            }),
            _c("candidate-recommendation-info", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeValue == 3,
                  expression: "activeValue == 3",
                },
              ],
              ref: "candidateRecommendationInfo",
              staticClass: "cloud-recommendation-tab-view",
              attrs: {
                id: "candidate-recommendation-info",
                "candidate-id": _vm.candidateDetailData.candidateId,
                "is-cloud-recommendation": true,
                "recommendation-id": _vm.recommendationid,
              },
              on: {
                "candidate-search-record-refresh": _vm.RecordListRefresh,
                "router-navigate": _vm.routernavigate,
                "cloud-candidate-refresh": _vm.cloudCandidateRefresh,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c("read-and-forward", {
              attrs: {
                isNoMore: _vm.isNoMore,
                readForwardLoading: _vm.readForwardLoading,
                listData: _vm.readForwardData,
              },
              on: { getMore: _vm.getReadAndForwardMore },
            }),
          ],
          1
        ),
      ]),
      _c("footer-container"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }