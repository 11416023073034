<template>
    <div class="cloud-recommendation">
        <candidateBaseInfoStore
            ref="candidateBaseInfoStore"
            :candidateDetailData="candidateDetailData"
            :candidate-id="candidateDetailData.id"
            :is-firm="candidateDetailData.isFirm"
            :resume-id="candidateDetailData.tbdResumeId"
            :recommendationid="recommendationid"
            :is-cloud-recommendation="true"
            :shareData="shareData"
        ></candidateBaseInfoStore>

        <div class="recommendation-content">
            <div class="pull-left">
                <div class="type-tab">
                    <div class="tab visible">
                        <ul class="selection-list">
                            <li class="selection-item visible"
                                v-for="item in nodeList" :key="item.value"
                                @click="routernavigate(item)"
                                :class="{'active':item.value == activeValue}">
                                <div class="tab-wrapper">
                                    <span>{{item.text}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <candidate-resume
                    class="cloud-recommendation-tab-view"
                    ref="candidateResume"
                    :activeValue="activeValue"
                    v-show="activeValue == 1 || activeValue == 4"
                    @candidate-detail-refresh="refreshdetail"
                    id="candidate-resume"
                    :shareData="shareData"
                    :candidateDetailData="candidateDetailData"
                    :candidate-id="candidateDetailData.candidateId"
                    :is-firm="true"
                    :can-edit="false"
                    :is-cloud-recommendation="true"
                    :resume-id="candidateDetailData.resumeId"></candidate-resume>
                <candidate-recommendation-info
                    class="cloud-recommendation-tab-view"
                    ref="candidateRecommendationInfo"
                    id="candidate-recommendation-info"
                    @candidate-search-record-refresh='RecordListRefresh'
                    :candidate-id="candidateDetailData.candidateId"
                    v-show="activeValue == 3"
                    @router-navigate="routernavigate"
                    :is-cloud-recommendation="true"
                    :recommendation-id="recommendationid"
                    @cloud-candidate-refresh="cloudCandidateRefresh"
                ></candidate-recommendation-info>
            </div>
            <div class="pull-right">
                <read-and-forward 
                    :isNoMore="isNoMore" 
                    :readForwardLoading="readForwardLoading"
                    :listData="readForwardData" 
                    @getMore="getReadAndForwardMore"
                ></read-and-forward>
            </div>
        </div>

        <footer-container></footer-container>
    </div>
</template>

<script>
    import FooterContainer from '@/hr/component/footer.vue';
    import {candidate as candidateConfig} from '#/js/config/api.json';
    import CandidateBaseInfoStore from '#/views/candidate-detail/layout/candidate-base-info-store/index.vue';
    import CandidateResume from '#/views/candidate-detail/layout/candidateResume.vue';
    import CandidateRecommendationInfo from '#/views/candidate-detail/layout/candidateRecommendationInfo';
    import ReadAndForward from '#/views/cloudRecommendation/cloudRecommendation-readAndForward';

    export default {
        name: "cloudRecommendation",
        components: {
            FooterContainer,
            CandidateBaseInfoStore,
            CandidateResume,
            CandidateRecommendationInfo,
            ReadAndForward
        },
        data() {
            return {
                candidateDetailData: {},
                nodeList: [
                    {
                        text: '简历',
                        url: '#/recommendation/' + this.$route.params.recommendationid,
                        value: 1
                    },
                    {
                        text: '推荐',
                        url: '#/recommendation/' + this.$route.params.recommendationid + '/recommendationList',
                        value: 3
                    },
                    {
                        text: '讨论',
                        url: '#/recommendation/' + this.$route.params.recommendationid + '/comments',
                        value: 4
                    }
                ],
                activeValue: 1,
                recommendationid: this.$route.params.recommendationid,
                readForwardData: [],
                isNoMore: false,
                readForwardLoading: false,
            }
        },
        watch: {
            // activeValue() {
            //     let recommendationid = this.$route.params.recommendationid;
            //     let rootURL = '/recommendation/' + recommendationid;
            //     this.toggleNavigator(this.$route.path, rootURL, this, recommendationid);
            //     this.beforeRoute(this.$route.path, rootURL, this, recommendationid);
            // }
        },
        computed: {
            resumeId() {
                return this.candidateDetailData.resumeId;
            },
            shareData() {
                let candidateDetail = this.candidateDetailData
                return {
                    resumeId: candidateDetail.resumeId,
                    candidateName: candidateDetail.realName,
                    title: candidateDetail.title || "-",
                    company: candidateDetail.company || "-",
                    cityName: candidateDetail.cityName || "-",
                    yearOfExperience: candidateDetail.yearOfExperience || '-',
                    degree: candidateDetail.degree || 0,
                    school: candidateDetail.school || ""
                }
            }
        },
        mounted() {
            window.recommendation = this;
            $('.ko-before').not('.ko-before-not-in').removeClass('ko-before');
            this.candidateBaseInfoStore = this.$refs.candidateBaseInfoStore;
            this.candidateDetail = this.$refs.candidateResume;
            this.candidateRecommendationInfo = this.$refs.candidateRecommendationInfo;
            this.candidateDetailComments = this.candidateDetail.$refs.comments;
            this.candidateResumeDetail = this.candidateDetail.candidateResumeDetail;
            this.getCloudRecommendation();
            this.getReadAndForward();
        },
        beforeRouteEnter(to, from, next) {
            let currentURL = to.path;
            let recommendationid = to.params.recommendationid;
            let rootURL = '/recommendation/' + recommendationid;
            next(vm => {
                vm.toggleNavigator(currentURL, rootURL, vm, recommendationid);
            })
        },
        beforeRouteUpdate (to, from, next) {
            let currentURL = to.path;
            let recommendationid = to.params.recommendationid;
            let rootURL = '/recommendation/' + recommendationid;
            this.toggleNavigator(currentURL, rootURL, this, recommendationid);
        },
        methods: {
            cloudCandidateRefresh() {
                this.getCloudRecommendation();
            },
            getCloudRecommendation() {
                return new Promise((resolve, reject) => {
                    let URL = `/Headhunting/RecommendationDetail/${this.$route.params.recommendationid}`;

                    _request({
                        method: 'GET',
                        url: URL
                    }).then(res => {
                        const data = Object.assign({}, res);
                        this.candidateDetailData = res;
                        delete data.candidateId;
                        data.longUrl = data.sharedLink;
                        data.tbdResumeId = res.resumeId;
                        this.candidateBaseInfoStore.handleOptXhrValue(data);
                        this.candidateDetailComments.setCommentType('recommendation');
                        this.candidateDetailComments.defaults.commentType = 'recommendation';
                        this.candidateDetailComments.refresh(this.recommendationid);
                        this.getResumeDetail();

                        let rootURL = '/recommendation/' + this.recommendationid;
                        this.beforeRoute(this.$route.path, rootURL, this.recommendationid);
                        this.$nextTick(() => {
                            const _this = this;
                            this.activeValue = this.nodeList.find(item => {
                                if (item.url === `#${_this.$route.path}`) {
                                    return item.value;
                                }
                            }).value;
                        })
                    }, err => {
                        console.log(err);
                    });
                })

            },
            getResumeDetail() {
                let resumeUrl = candidateConfig.url_candidateDetail.replace('%p', this.resumeId);
                _request({
                    method: 'GET',
                    url: resumeUrl
                }).then(res => {
                    res.mobild = this.candidateDetailData.mobild;
                    res.email = this.candidateDetailData.email;
                    this.candidateDetail.updateArticle(res);
                }, err => {
                    console.log(err);
                })
            },
            getReadAndForward(start = 0, take = 10) {
                let Url = `/Recommendation/${this.$route.params.recommendationid}/ReadRecords`;
                this.readForwardLoading = true;
                _request({
                    method: 'GET',
                    url: Url,
                    data: {
                        start: start,
                        take: take
                    }
                }).then(res => {
                    if (res && res.length > 0) {
                        this.readForwardData = this.readForwardData.concat(res);
                        if (res.length === 10) {
                            this.isNoMore = false;
                        } else {
                            this.isNoMore = true;
                        }
                    }
                }, err => {
                    console.log(err);
                }).finally(() => {
                    this.readForwardLoading = false;
                })
            },
            getReadAndForwardMore() {
                this.getReadAndForward(this.readForwardData.length, 10);
            },
            beforeRoute(currentURL, rootURL, recommendationid) {
                switch (currentURL) {
                    case rootURL:
                        break;
                    case rootURL + '/recommendationList':
                        // this.candidateRecommendationInfo.$refs.candidateRecommendationRecord.recordInit(this.candidateDetailData.id);
                        this.candidateRecommendationInfo.$refs.candidateRecommendationDetail.refresh(this.recommendationid);
                        break;
                    case rootURL + '/comments':
                        this.candidateDetailComments.refresh(this.candidateDetailData.id);
                        break;
                }
            },
            routernavigate(vm) {
                let resumeId = this.resumeId;
                let recommendationid = this.$route.params.recommendationid;
                let rootURL = '#/recommendation/' + recommendationid;

                if (vm.recommendationid) {
                    recommendationid = vm.recommendationid
                }
                this.activeValue = vm.value;
                location.href = vm.url;
                this.beforeRoute(vm.url, rootURL, recommendationid);
            },
            refreshdetail() {

            },
            RecordListRefresh() {

            },
            toggleNavigator(currentURL, rootURL, vm, recommendationid) {
                // vm.candidateSearchRecord.toggleActive(true);
                switch (currentURL) {
                    case rootURL:
                        this.activeValue = 1;
                        break;
                    case rootURL + '/recommendationList':
                        this.activeValue = 3;
                        this.$nextTick(() => {
                            this.candidateRecommendationInfo && this.candidateRecommendationInfo.toggleState('detail');
                        });
                        break;
                    case rootURL + '/comments':
                        this.activeValue = 4;
                        break;
                }
                ;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '#/css/scss/mixins/_clearfix.scss';

    @media screen and (max-width: 1400px) {
        .recommendation-content {
            .pull-left {
                width: calc(100% - 310px) !important;
            }
            .pull-right {
                width: 290px !important;
            }
        }
    }
    @media screen and (min-width: 1400px) and (max-width: 1680px) {
        .recommendation-content {
            .pull-left {
                width: calc(100% - 410px) !important;
            }
            .pull-right {
                width: 390px !important;
            }
        }
    }
    @media screen and (min-width: 1680px) and (max-width: 1800px) {
        .recommendation-content {
            .pull-left {
                width: calc(100% - 440px) !important;
            }
            .pull-right {
                width: 420px !important;
            }
        }
    }

    .before-main-body {
        padding: 20px;
        height: 100%;
        overflow-y: auto;
    }
    .cloud-recommendation {
        // margin: auto;
        // width: 1200px;

        &:before, &:after {
            content: '';
            display: table;
            clear: both;
        }

        .recommendation-content {
            width: 1200px;
            margin: 10px auto 0;
            padding-bottom: 60px;
            display: flex;
            justify-content: space-between;
            .pull-left {
                // float: left;
                width: calc(100% - 460px);
                background-color: #fff;
                border-radius: 8px;
            }

            .pull-right {
                // float: right;
                width: 460px;
                margin-left: 20px;
                background-color: #fff;
                border-radius: 8px;
            }
        }
    }

    .type-tab {
        // background-color: #fff;
        // width: 830px;
        // border: 1px solid #ddd;
        // margin-top: 20px;
        border-bottom: 1px solid #eee;

        @include clearfix();

        .tab {
            border-bottom: none;

            .selection-item {
                float: left;
                bottom: 0px;
                width: auto;
                min-width: 46px;
                font-size: 16px;
                border-bottom: none;
                // background-color: #fff;
                padding: 0 15px 0;
                cursor: pointer;

                &:before {
                    display: none;
                }

                &:nth-of-type(1) {
                    padding-left: 30px;
                }

                .tab-wrapper {
                    height: 40px;
                    line-height: 40px;
                    padding: 0;
                }
            }

            .active {
                .tab-wrapper {
                    color: #38bc9c;
                    border: none;
                    border-bottom: 2px solid #38bc9c;
                }

                &:before {
                    display: none;
                }
            }

        }

        &.has-unprocessed-item .selection-list {
            li:nth-of-type(2) > .tab-wrapper {
                &:before {
                    content: '●';
                    color: red;
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
</style>
<style lang="scss">
    .cloud-recommendation {
        .candidate-base-info{
            min-height: 150px;
        }

        .candidate-communication-list{
            border: none;
        }

        .cloud-recommendation-tab-view {
            .candidate-resume-detail {
                margin: 0;
                padding: 0;
                width: 100%;
            }

            .candidate-resume-comments{
                padding: 20px;
                background: #fff;
                border: 1px solid #ddd;
                border-top: none;

                .comments.row.row-overflow{
                    margin-top: 30px;
                }
            }

            .candidate-recommendation-detail{
                .phone-call-test,.title>a:first-child{
                    display: none;
                }

                .recommend-angle{
                    margin-left: 0;
                }

                .standard-resume .resume-module .item-header p:first-child:after{
                    font-size: 12px;
                    line-height: 28px;
                }

                .standard-resume .resume-module .item-header p:first-child:before{
                    font-size: 28px;
                }
            }

            .candidate-resume, .communication-list, .candidate-recommendation-record,.recommendation-list {
                // width: 830px;
                overflow: hidden;
            }

            &.active {
                display: block !important;
            }
        }


    }
</style>
